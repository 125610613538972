<template>
  <v-container style="max-width: 600px; height: 100%" class="pa-6 pt-10">
    <template>
      <v-card>
        <div class="px-2 text-overline">ポイント残高</div>
        <v-divider></v-divider>
        <div class="px-2 py-4 d-flex justify-space-between text-h6">
          <div>ご利用可能ポイント：</div>
          <div>{{ point }}p</div>
        </div>
      </v-card>
      <v-list>
        <div class="pt-4 text-overline">ポイント履歴</div>
        <v-tabs v-model="showType" grow>
          <v-tab key="all">全履歴</v-tab>
          <v-tab key="add">獲得</v-tab>
          <v-tab key="sub">利用・キャンセル</v-tab>
        </v-tabs>
        <v-list v-for="item in pointHistory" :key="item._id">
          <v-divider></v-divider>
          <div class="d-flex justify-space-between text-caption">
            <div>{{ $dayjs(item.updatedAt).format('YYYY/MM/DD') }}</div>
            <v-chip x-small label :ripple="false">{{ item.category }}</v-chip>
          </div>

          <div class="d-flex justify-space-between align-start">
            <div>
              <span class="text-caption">{{ item.description }}</span>
            </div>
            <div style="min-width: 90px" class="text-right">
              <span class="text-subtitle-2">
                <span v-if="item.type === 'add'">+{{ item.point }}p</span>
                <span v-else class="red--text text--darken-4">-{{ item.point }}p</span>
              </span>
            </div>
          </div>
        </v-list>
      </v-list>
    </template>
  </v-container>
</template>

<script>
  import Methods from '@/api/method.js';

  export default {
    name: 'PointHistory',

    data() {
      return {
        point: 0,
        showType: 0,
        pointHistoryRaw: [],
      };
    },

    mounted() {
      this.fetchPointHistory();
    },

    computed: {
      pointHistory() {
        return this.pointHistoryRaw[this.showType];
      },
    },

    methods: {
      async fetchPointHistory() {
        try {
          const userAPI = await Methods.GetUser({
            id: this.$store.state.user.user_id,
            // id: '63069d2d088d3542647c506e',
          });
          this.point = userAPI.data.point_amount || 0;
          const pointHistoryAPI = await Methods.GetPointHistory({
            status_in:"[1,2]",
            user_id: this.$store.state.user.user_id,
            // user_id: '633950083e30803b356fc17e',
          });
          console.log("63c8b4b87113883087493254")
          const pointHistory = pointHistoryAPI.data.pointhistorys || [];
          this.pointHistoryRaw = [
            pointHistory,
            pointHistory.filter((item) => item.type === 'add'),
            pointHistory.filter((item) => item.type === 'sub'),
          ];
        } catch (error) {
          console.log(error);
        }
      },
    },
  };
</script>
